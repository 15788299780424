.properties-editor {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 10px;
  }
  
  .editor-message {
    padding: 10px;
    background-color: #f8f9fa;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-top: 20px;
  }
  
  .property-field {
    margin-bottom: 10px;
  }
  
  .property-label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .property-input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    box-sizing: border-box; /* Ensures padding does not add to the width */
  }
  
  
  .save-button:hover {
    background-color: #0056b3;
  }
  