/* App.css or similar */
.dtdl-schemas-list {
    overflow-y: auto;
    padding: 10px;
  }
  
  .dtdl-schemas-list h3 {
    margin-top: 0;
  }
  
  .dtdl-schemas-list ul {
    list-style: none;
    padding: 0;
  }
  
  .dtdl-schemas-list li {
    cursor: pointer;
    padding: 5px 0;
  }
  
  .dtdl-schemas-list li:hover {
    background-color: #f0f0f0;
  }
  