/* AddRelationshipSection.css */
.add-relationship-section button {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .add-relationship-section button:hover {
    background-color: #0056b3;
  }
  
  .relationship-form {
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: max-height 0.3s ease-out;
  }
  
  .relationship-form label,
  .relationship-form select,
  .relationship-form .save-btn {
    display: block;
    margin-bottom: 15px;
  }
  
  
