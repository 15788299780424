.object-creation-form {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
  }
  
  .object-creation-form h2 {
    margin-top: 0;
    margin-bottom: 20px;
    color: #333;
  }
  
  .object-creation-form label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    color: #555;
  }
  
  .object-creation-form input[type="text"],
  .object-creation-form select {
    width: 100%;
    padding: 8px 12px;
    margin-bottom: 5px; /* Space between each field */
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .object-creation-form button {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 15px;
    transition: background-color 0.3s;
  }
  
  .object-creation-form button:hover {
    background-color: #0056b3;
  }
  