.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* src/App.css */
.App {
  display: flex;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  /* Use a web-safe font or import a custom one */
}


/* NavBar styling to ensure it fills the width and stays at the top */
nav {
  width: 100%;
  /* Ensure nav bar fills the width of the viewport */
  background: #333;
  color: #fff;
  padding: 5px 0;
  position: fixed;
  /* Fix position at the top */
  top: 0;
  /* Align to the top */
  z-index: 1000;
  /* Ensure it stays above other content */
}

nav ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  /* Align items to the left */
  padding: 0;
  margin: 0;
}

nav ul li {
  margin: 0 20px;
}

nav ul li a {
  text-decoration: none;
  color: #fff;
}

/* Additional padding for the body to ensure content doesn't hide behind the fixed NavBar */
body {
  /* Adjust based on the actual height of your NavBar */
}

.container {
  display: flex;
  flex-basis: auto;
  flex-direction: row;
  align-items: stretch;
  height: 90vh;
  background-color: #f5f5f5;
  /* Light background for the overall layout */
}

.leftPanel {
  width: 300px;
  /* White background for contrast */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  /* Subtle shadow for depth */
  overflow-y: auto;
  /* Scroll vertically if needed */
  padding: 20px;
}

.rightPanel {
  width: 300px;
  background-color: #fff;
  /* White background for contrast */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding: 20px;
  /* Subtle shadow for depth */
}

.propertiesPanel {
  flex-grow: 1;
  background-color: #eef2f5;
  /* Slightly different background for distinction */
  overflow-y: auto;
  padding: 20px;
  border-left: 1px solid #ccc;
  /* Subtle separation line */

}

.propertiesPanel.open {
  transform: translateX(0);
  /* Slide in */
}

/* Additional styles for improved interaction and aesthetics */
ul {
  list-style-type: none;
  /* Remove default list styling */
  padding: 0;
}

li {
  padding: 10px 0;
  /* Space out list items */
  cursor: pointer;
}

li:hover {
  background-color: #f0f0f0;
  /* Highlight on hover */
}

span,
button {
  margin-right: 10px;
}

button {
  background-color: #007bff;
  color: white;
  padding: 4px 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

button:hover {
  background-color: #0056b3;
}

.folder {
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
  max-height: 0;
  /* Start collapsed */
  opacity: 0;
  /* Start transparent */
}

.folder.open {
  max-height: 500px;
  /* Adjust based on content size */
  opacity: 1;
  /* Fully visible */
}

select {
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  color: black;
}

form {
  padding: 10px;
  margin: 5px 0px;
  border-radius: 5px;
  border: 1px solid rgb(204, 204, 204);
  background-color: #f8f9fa;
}

/* Add any additional styling for your folder and file icons here */